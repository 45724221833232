import { message } from 'antd';
import api from '../api';

export default async () => {
  try {
    const { data } = await api.get(`/setting/support-link`)
    if(data.data) {
      //console.log(data.data);
      if (data.data.length >=1) {
        window.location.assign(data.data);
      } else {
        message.error('Xảy ra lỗi, vui lòng thử lại sau');
      }
    }

  } catch (err) {
    //console.log("Lỗi", err);
    message.error('Xảy ra lỗi, vui lòng thử lại sau');
  }
};